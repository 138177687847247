import React from "react";

import Layout from "../components/layout";
import Head from "../components/head";
import pubStyles from "./publications.module.scss";
import { graphql } from "gatsby";

const Publications = ({ data }) => {
  const publications = [
    {
      year: "2020",
      authors: "Sadegh Rabiee, Joydeep Biswas",
      publication:
        "IV-SLAM: Introspective Vision for Simultaneous Localization and Mapping. arXiv preprint arXiv:2008.02760, -, pp. -.",
      name: "arxiv_ivslam",
    },
    {
      year: "2020",
      authors: "Shunhua Bai, Junfeng Jiao",
      publication:
        "From Shared Micro-Mobility to Shared Responsibility: Using Crowdsourcing to Understand Dockless Vehicle Violations in Austin, Texas. Journal of Urban Affairs.",
      name: "shared_mobility",
    },
    {
      year: "2020",
      authors: "Shunhua Bai, Junfeng Jiao",
      publication:
        "Dockless E-scooter Usage Patterns and Urban Built Environments: A Comparison Study of Austin, TX, and Minneapolis, MN. Travel Behavior and Society. 20, 264-272.",
      name: "",
    },
    {
      year: "2020",
      authors: "Junfeng Jiao, Fangru Wang",
      publication:
        "Shared Mobility and Transit-dependent Population: A New Equity Opportunity or Issue? International Journal of Sustainable Transportation.",
      name: "mobility_transit_dependent",
    },
    {
      year: "2020",
      authors: "Junfeng Jiao, Shunhua Bai",
      publication:
        "Understanding the Shared E-scooter Travels in Austin, TX. ISPRS International Journal of Geo-Information. 9(2), 135",
      name: "understanding_escooter",
    },
    {
      year: "2019",
      authors: "Minkyu Kim, Luis Sentis",
      publication:
        "Active Object Tracking using Context Estimation: Handling Occlusions and Detecting Missing Targets",
      name: "active_object_tracking",
    },
    {
      year: "2019",
      authors: "Sadegh Rabiee, Joydeep Biswas",
      publication:
        "IVOA: Introspective Vision for Obstacle Avoidance. In Intelligent Robots and Systems (IROS), IEEE/RSJ International Conference on, pp. 1230–1235.",
      name: "iros2019_ivoa",
    },
    {
      year: "2019",
      authors: "Sadegh Rabiee, Joydeep Biswas",
      publication:
        "A Friction-Based Kinematic Model for Skid-Steer Wheeled Mobile Robots. In Robotics and Automation (ICRA), IEEE International Conference on, pp. 8563-8569.",
      name: "icra2019_skid_steer",
    },
    {
      year: "2017",
      authors: "Sourish Ghosh, Joydeep Biswas",
      publication:
        "Joint Perception And Planning For Efficient Obstacle Avoidance Using Stereo Vision. In Intelligent Robots and Systems (IROS), IEEE/RSJ International Conference on, pp. 1026–1031.",
      name: "jpp",
    },
    {
      year: "2017",
      authors: "Joydeep Biswas, Manuela M. Veloso",
      publication:
        "Episodic non-Markov localization. Robotics and Autonomous Systems, 87, pp. 162 - 176.",
      name: "ras_episodic_nonmarkov_localization",
    },
    {
      year: "2016",
      authors: "Joydeep Biswas, Manuela Veloso",
      publication:
        "The 1,000-km Challenge: Insights and Quantitative and Qualitative Results. IEEE Intelligent Systems, 31(3), pp. 86–96.",
      name: "intelligent_systems2016_1000km",
    },
  ];

  const getUrl = name => {
    if (name === "mobility_transit_dependent") {
      return "https://doi.org/10.1080/15568318.2020.1747578";
    }
    const node = data.allFile.edges.find(file => file.node.name === name);
    return node ? node.node.publicURL : "#";
  };

  return (
    <Layout>
      <Head title="Publications" />
      <article className={pubStyles.content}>
        <h2>Publications</h2>
        <p>
          The SMADS project draws on relevant research in robotics, engineering,
          and urban planning in order to further advance research in these
          disciplines. Below are listed relevant publications which have
          contributed to work on the SMADS project.
        </p>
        <table className={pubStyles.table}>
          <thead>
            <tr>
              <th>Year</th>
              <th>Authors</th>
              <th>Title</th>
              <th>PDF</th>
            </tr>
          </thead>
          <tbody>
            {publications.map((paper, i) => (
              <tr key={i}>
                <td>{paper.year}</td>
                <td>{paper.authors}</td>
                <td>{paper.publication}</td>
                <td>
                  <a href={getUrl(paper.name)}>PDF</a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </article>
    </Layout>
  );
};

export const query = graphql`
  query {
    allFile(filter: { extension: { eq: "pdf" } }) {
      edges {
        node {
          publicURL
          name
        }
      }
    }
  }
`;

export default Publications;
